import React from 'react';

const ReactGatsbyIntro = () => (
  <div className="hero is-info is-bold is-large">
    <div className="hero-body">
      <div className="responsive-iframe-wrapper">
        <iframe
          title="ayyyyy"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/53969kEeoc0?rel=0&amp;showinfo=0"
          frameBorder="0"
          allowFullScreen
        />
      </div>
      {/* <h1 className="title is-1">This is the Intro to React Gatsby Course.</h1> */}
    </div>
  </div>
);

export default ReactGatsbyIntro;
